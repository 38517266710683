body {
  background: #d61616;
  min-height: 100vh;
  font-family: questrial;
}

.card {
  position: relative;
  cursor: pointer;
}

.card .face {
  height: 200px;
  width: 250px;
  transition: 0.5s;
}

.card .face.face1 {
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.card:hover .face.face1 {
  background: #ef4444;
  transform: translateY(0);
}

.card .face.face1 .content {
  opacity: 1;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card .face.face1 .content img {
  max-width: 100px;
}

.card:hover .face.face1 .content {
  opacity: 1;
}

.card .face.face2 {
  position: relative;
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.card:hover .face.face2 {
  transform: translateY(0);
}
